.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.fileInput{
  background-color: #2273c9;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
}

.fileInput:focus,
.fileInput:hover {
  background-color: #17579b;
}